<template>
  <section class="doc-chapter-demo-wrap">
    <div class="doc-chapter__content">
      <div class="doc-chapter__player">
        <div id="player"></div>
      </div>
      <div class="doc-chapter__info">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
import eventBus from './event'
import { APPID, RECORDID } from './mixin'
export default {
  data() {
    return {
      vhallplayer: null,
      slectedDocIds: '',
      selectDocNames: []
    }
  },
  created() {
    if (this.$route.name !== 'select-doc') {
      this.$router.push({ name: 'select-doc' })
    }
    this.getAccessToken()
    eventBus.$on('playerPause', () => {
      this.vhallplayer.pause()
    })
    eventBus.$on('getDocNames', data => {
      this.selectDocNames = data.selectDocNames
    })
    this.delChapterInfo()
  },
  methods: {
    delChapterInfo() {
      let params = {
        app_id: APPID,
        vod_id: RECORDID
      }
      this.$fetch('delDoc', params).then(res => {
        console.log('删除章节成功')
      })
    },
    getAccessToken() {
      this.$fetch('getAccessToken', { app_id: APPID }).then(({ data }) => {
        const config = {
          appId: APPID,
          accountId: data[0].accountId,
          token: data[0].token
        }
        this.accountId = data[0].accountId
        this.initPlayer(config)
      })
    },
    initPlayer(config) {
      this.vhallplayer && this.vhallplayer.destroy()
      const loading = this.$loading()
      const opt = {
        ...config,
        type: 'vod',
        videoNode: 'player',
        vodOption: {
          recordId: RECORDID
        }
      }
      const success = event => {
        console.log('初始化播放器成功')
        this.vhallplayer = event.vhallplayer
        this.vhallplayer.openControls(true)
        this.vhallplayer.openUI(false)
        loading.close()
      }
      const failure = function(e) {
        loading.close()
        console.log(e, '失败')
      }
      this.$nextTick(() => {
        VhallPlayer.createInstance(opt, success, failure)
      })
    }
  },
  beforeDestroy() {
    this.vhallplayer.destroy()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/varible.less';
.doc-chapter-demo-wrap {
  height: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  .doc-chapter__content {
    display: flex;
    justify-content: space-between;
    min-width: 1200px;
    max-width: 1600px;
    height: 100%;
    min-height: 405px;
    margin: 0 auto;
    .doc-chapter__player {
      width: 720px;
      #player {
        background: #000;
      }
    }
    .doc-chapter__info {
      flex: 1;
      width: 430px;
      min-width: 500px;
      padding: 20px;
      margin-left: 20px;
      background: #f5f6f7;
      border-radius: 4px;
    }
  }
}
</style>
